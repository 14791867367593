<template>
  <a-card>
    <div class="d-flex justify-content-between align-items-center mb-3">
      <span class="h5 mb-0">Languages</span>
      <a-button type="primary" size="large" style="font-size: 16px; font-weight: 300; border-radius: 2px;" @click="showLangModal">
        <a-icon type="plus" /> Add New
      </a-button>
    </div>
    <a-table :columns="langColumns" :data-source="langData">
      <template
        v-for="col in ['name', 'desc']"
        :slot="col"
        slot-scope="text, record"
      >
        <div :key="col">
          <a-input
            v-if="record.editable"
            style="margin: -5px 0"
            :value="text"
            @change="e => handleLangChange(e.target.value, record.id, col)"
          />
          <template v-else>
            {{ text }}
          </template>
        </div>
      </template>
      <template slot="operation" slot-scope="text, record" >
        <div class="editable-row-operations" v-if="checkPermissions(user.permissions, 'settings', 'edit')">
          <span v-if="record.editable">
            <a @click="() => saveLang(record.id)" class="text-success"><a-icon type="save" /> Save</a>
            <br>
            <a-popconfirm title="Sure to cancel?" @confirm="() => cancelLang(record.id)">
              <a class="text-danger">
                <a-icon type="close" /> Cancel
                </a>
            </a-popconfirm>
          </span>
          <span v-else>
            <a href="javascript:;" class="text-primary font-size-18" :disabled="editingLangKey !== ''" @click="() => editLang(record.id)">
              <a-icon type="edit" />
            </a>
          </span>
          <span v-if="checkPermissions(user.permissions, 'settings', 'delete') && !record.editable">
            <a-popconfirm
              v-if="langData.length"
              title="Sure to delete?"
              @confirm="() => onDeleteLang(record.id)"
            >
              <a href="javascript:;" class="text-danger font-size-18">
                <a-icon type="delete" />
              </a>
            </a-popconfirm>
          </span>
        </div>
      </template>
    </a-table>
    <!-- <a-modal v-model="newLangModal"
      title="Add New Language"
      ok-text="Ok"
      cancel-text="Cancel"
      @ok="addLangSubmit"
      class="add-lang-modal olimp-admin-modal"
      width="520px"
      :destroyOnClose="true"
    > -->
    <a-modal v-model="newLangModal"
      title="Add New Language"
      class="add-lang-modal olimp-admin-modal"
      width="520px"
      :destroyOnClose="true"
      footer=''
    >
      <a-form-model
        ref="addLangForm"
        :model="addLangForm"
        :rules="addLangRules"
      >
        <a-form-model-item ref="id" label="ID" prop="id">
          <a-input v-model="addLangForm.id" placeholder="Input ID" />
        </a-form-model-item>
        <a-form-model-item ref="name" label="Short Name" prop="name">
          <a-input v-model="addLangForm.name" placeholder="Input short name" />
        </a-form-model-item>
        <a-form-model-item ref="desc" label="Full Name" prop="desc">
          <a-input v-model="addLangForm.desc" placeholder="Input full name" />
        </a-form-model-item>
      </a-form-model>
      <hr>
      <div class="d-flex">
        <a-col class="text-left" :span="12">
          <CustomButton @click="hideLangModal">
            Cancel
          </CustomButton>
        </a-col>
        <a-col class="text-right" :span="12">
          <a-button type="primary" @click="addLangSubmit">
            Add
          </a-button>
        </a-col>
      </div>
    </a-modal>
  </a-card>
</template>

<script>
import { mapState, mapActions } from 'vuex'
// import apiClient from '@/services/axios'
import banmix from '@/services/bannermix.js'
import accessMix from '@/services/accessmix.js'
import CustomButton from '@/components/custom/button/CustomButton'

const langColumns = [
  {
    title: 'ID',
    dataIndex: 'id',
    width: '20%',
    scopedSlots: { customRender: 'id' },
  },
  {
    title: 'Short Name',
    dataIndex: 'name',
    width: '20%',
    scopedSlots: { customRender: 'name' },
  },
  {
    title: 'Full Name',
    dataIndex: 'desc',
    width: '30%',
    scopedSlots: { customRender: 'desc' },
  },
  {
    title: 'Actions',
    dataIndex: 'operation',
    width: '30%',
    align: 'right',
    scopedSlots: { customRender: 'operation' },
  },
]
// var langData = []
// function getLocalizations() {
//   const url = '/admin/localizations'
//   apiClient.get(url).then((response) => {
//     langData = response.data.data
//   }).catch(error => { console.log(error) })
// }
// getLocalizations()

export default {
  name: 'languages',
  mixins: [banmix, accessMix],
  components: {
    CustomButton,
  },
  computed: {
    ...mapState(['user', 'langs']),
    // ...mapGetters(['langs']),
    langData() {
      console.log(this.$store.getters.langs, 'comp langs.langdata')
      return this.langs.langData
    },
    cacheLangData() {
      console.log(this.$store.getters.langs.cacheLangData, 'comp cacheLangData.langdata')
      return this.langs.cacheLangData
    },
  },
  data() {
    // this.cacheLangData = langData.map(item => ({ ...item }))
    return {
      // langData,
      langColumns,
      editingLangKey: '',
      newLangModal: false,
      addLangForm: {
        id: undefined, // Math.max.apply(Math, langData.map(function(o) { return o.id })) + 1,
        name: '',
        desc: '',
      },
      addLangRules: {
        id: [{ required: true, message: 'Please input ID', trigger: 'blur' }],
        name: [{ required: true, message: 'Please input short name', trigger: 'blur' }],
        desc: [{ required: true, message: 'Please input full name', trigger: 'blur' }],
      },
    }
  },
  mounted () {
    // this.getLocalizations()
    // this.getLangs()
    if (this.langs.langData.length === 0) {
      this.GET_LANGS()
    }
  },
  methods: {
    ...mapActions(['GET_LANGS']),
    // ...mapMutations(['SET_LANGS']),
    // getLocalizations() {
    // this.$store.dispatch('GET_LANGS')
    // console.log(this.langs.langData, 'langdata')
    // const url = '/admin/localizations'
    // apiClient.get(url).then((response) => {
    //   this.langData = response.data.data
    //   this.cacheLangData = this.langData.map(item => ({ ...item }))
    //   langData = this.langData
    // }).catch(error => { console.log(error) })
    // },
    showLangModal() {
      this.newLangModal = true
    },
    hideLangModal() {
      this.newLangModal = false
    },
    resetAddLangForm() {
      this.$refs.addLangForm.resetFields()
    },
    addLangSubmit() {
      this.$refs.addLangForm.validate(valid => {
        if (valid) {
          // const url = '/admin/localizations'
          // return apiClient.post(url, this.addLangForm).then((response) => {
          //   this.$notification.success({
          //     message: '"' + response.data.data.desc + '" language successfully created',
          //     description: '',
          //   })
          //   console.log(this.$notification, '$notification')
          //   const { langData } = this
          //   const newLang = response.data.data
          //   this.langData = [...langData, newLang]
          //   this.cacheLangData = this.langData.map(item => ({ ...item }))
          //   this.resetAddLangForm()
          //   this.hideLangModal()
          // }).catch(error => {
          //   console.log(error)
          //   this.$notification.error({
          //     message: 'Error while creating new language',
          //     description: error.message,
          //   })
          // })
          console.log(this.addLangForm, 'langformM')
          this.$store.dispatch('ADD_LANG', this.addLangForm)
          this.resetAddLangForm()
          this.hideLangModal()
        } else {
          return false
        }
      })
    },
    handleLangChange(value, key, column) {
      const newData = [...this.langData]
      const target = newData.filter(item => key === item.id)[0]
      if (target) {
        target[column] = value
        this.langData = newData
      }
    },
    onDeleteLang(key) {
      this.$store.dispatch('DELETE_LANG', key)
      // const dataSource = [...this.langData]
      // const target = dataSource.filter(item => key === item.id)[0]
      // const url = '/admin/localizations/' + key
      // apiClient.delete(url).then((response) => {
      //   this.$notification.success({
      //     message: 'Language "' + target.desc + '" successfully deleted',
      //     description: '',
      //   })
      //   this.langData = dataSource.filter(item => item.id !== key)
      //   this.cacheLangData = this.langData.map(item => ({ ...item }))
      // }).catch(error => {
      //   console.log(error)
      //   this.$notification.error({
      //     message: 'Error while deleting language',
      //     description: error.message,
      //   })
      // })
    },
    editLang(key) {
      this.editingLangKey = key
      this.$store.dispatch('EDIT_LANG', key)
      // const newData = [...this.langData]
      // const target = newData.filter(item => key === item.id)[0]
      // if (target) {
      //   target.editable = true
      //   // this.langData = newData
      //   this.SET_LANGS(newData)
      // }
    },
    saveLang(key) {
      this.editingLangKey = ''
      this.$store.dispatch('SAVE_LANG', key)
      // console.log(key, 'saveLang')
      // const newData = [...this.langData]
      // const newCacheData = [...this.cacheLangData]
      // const target = newData.filter(item => key === item.id)[0]
      // const targetCache = newCacheData.filter(item => key === item.id)[0]
      // if (target && targetCache) {
      //   const url = '/admin/localizations/' + target.id
      //   return apiClient.patch(url, target).then((response) => {
      //     this.$notification.success({
      //       message: '"' + response.data.data.desc + '" language successfully updated',
      //       description: '',
      //     })
      //     delete target.editable
      //     this.langData = newData
      //     Object.assign(targetCache, target)
      //     this.cacheLangData = newCacheData
      //     this.editingLangKey = ''
      //   }).catch(error => {
      //     console.log(error)
      //     this.$notification.error({
      //       message: 'Error while updating language',
      //       description: error.message,
      //     })
      //   })
      // }
      // this.editingLangKey = ''
    },
    cancelLang(key) {
      this.editingLangKey = ''
      this.$store.dispatch('CANCEL_LANG', key)
      // const newData = [...this.langData]
      // const target = newData.filter(item => key === item.id)[0]
      // if (target) {
      //   Object.assign(target, this.cacheLangData.filter(item => key === item.id)[0])
      //   delete target.editable
      //   // this.langData = newData
      //   this.SET_LANGS(newData)
      // }
    },
  },
}
</script>
<style scoped>
  .editable-row-operations a {
    margin-right: 8px;
  }
</style>
