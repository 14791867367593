import apiClient from '@/services/axios'
const sportsAr1 = new Map([
  ['football', 'Футбол'],
  ['tennis', 'Теннис'],
  ['voleyball', 'Волейбол'],
])
const champsAr1 = new Map([
  ['champ1', 'Чемпионат 1'],
  ['champ2', 'Чемпионат 2'],
  ['champ3', 'Чемпионат 3'],
])
const matchesAr1 = new Map([
  ['match1', 'Матч 1'],
  ['match2', 'Матч 2'],
  ['match3', 'Матч 3'],
])
const displayOptions = [
  { label: 'Live', value: 1 },
  { label: 'Promo', value: 3 },
  { label: 'Main', value: 4 },
]
// const langOptions = [
//   { label: 'English', value: 2 },
//   { label: 'Russian', value: 0 },
//   { label: 'Kazakh', value: 1 },
//   { label: 'Ukranian', value: 9 },
// ]
var appDataDef = []
var langOptions = []
function getApplications() {
  const url = '/admin/applications'
  apiClient.get(url).then((response) => {
    appDataDef = response.data.data
  }).catch(error => { console.log(error) })
}
function getLocalizations() {
  const url = '/admin/localizations'
  apiClient.get(url).then((response) => {
    langOptions = response.data.data.map(function(row) {
      return { value: row.id, label: row.desc }
    })
  }).catch(error => { console.log(error) })
}
getApplications()
getLocalizations()

export default {
  data () {
    return {
      bannerDisplays: displayOptions,
      bannerLangs: langOptions,
      sportsAr: sportsAr1,
      champsAr: champsAr1,
      matchesAr: matchesAr1,
      bannerApps: appDataDef,
    }
  },
  created: function () {
    console.log('created banner Mixin')
  },
  mounted () {
    console.log('Mounted banner Mixin')
    // console.log(this.bannerApps)
  },
  computed: {
    // ---
  },
  methods: {
    testMessage: function () {
      console.log('Now printing from a banners mixin function')
    },
    // getApplications() {
    //   const url = '/admin/applications'
    //   return apiClient.get(url).then((response) => {
    //     // console.log(response.data.data)
    //     return response.data.data
    //   }).catch(error => { console.log(error) })
    // },
  },
}
